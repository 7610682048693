import { helpers } from "vuelidate/lib/validators";
import { useStore } from "vuex";
import { computed } from "vue";

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const createToast = (toast, severity, summary, detail, life = 5000) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: life,
  });
};

export const mergeIds = (arr) => {
  let idsObjArr;
  arr.value.forEach(
    (user) => (idsObjArr = idsObjArr ? [...idsObjArr, user.id] : [user.id])
  );
  return idsObjArr.toString();
};

// VALIDATORS AND FORMS UTILITIES
export let generatePassword = () => {
  const chars = [
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
    "0123456789",
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
    "abcdefghijklmnopqrstuvwxyz",
    "ABCDEFGHIJKLMNOPQRSTUVWXY",
  ];
  return [3, 3, 2, 1, 1]
    .map(function (len, i) {
      return Array(len)
        .fill(chars[i])
        .map(function (x) {
          return x[Math.floor(Math.random() * x.length)];
        })
        .join("");
    })
    .concat()
    .join("")
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("")
    .split("0x")
    .join("");
};

export const loginKeypress = (e) => {
  if (/[^a-zA-Z0-9.@_-]/g.test(e.key)) {
    e.preventDefault();
  }
};

export const nameKeypress = (e) => {
  if (/[^a-zA-Z]/g.test(e.key)) {
    e.preventDefault();
  }
};

export const emailKeypress = (e) => {
  if (/[^a-zA-Z0-9.@_-]/g.test(e.key)) {
    e.preventDefault();
  }
};

export const timeKeydown = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

const regExLogin = "[a-zA-Z0-9@_.~-]";
const regExName = "[a-zA-Z~]";
const regExPass =
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d@#$%&-_*+=|{}:;!.?()\\[\\]]{8,}$";
//eslint-disable-next-line
const regExEmail = "^[a-zA-Z0-9_\.~-]+@[a-zA-Z0-9_\.~-]+\\.[a-zA-Z0-9_\.~-]+$";

export const validationRegExLogin = (value) => value.search(regExLogin) >= 0;
export const validationRegExName = (value) => value.search(regExName) >= 0;
export const validationRegExEmail = (value) => value.search(regExEmail) >= 0;

export const validationRegExPass = (value) => {
  if (!helpers.req(value)) {
    return true;
  }
  const match = value.search(regExPass);
  return match >= 0;
};

// eslint-disable-next-line no-unused-vars
export const validationDuplicateEmail = (errorMsg) => (value) => {
  const valid =
    errorMsg.value === "The user data.email has already been taken." ? -1 : 0;
  return valid >= 0;
};

// eslint-disable-next-line no-unused-vars
export const validationFromAPI = (errorMsg, type) => (value) => {
  let valid;
  switch (type) {
    case "email":
      valid = errorMsg.value.includes("email") ? -1 : 0;
      break;
    case "password":
      valid = errorMsg.value.includes("password") ? -1 : 0;
      break;
  }
  return valid >= 0;
};

// eslint-disable-next-line no-unused-vars
export const validationAuthEmc = (errorMsg) => (value) => {
  const valid =
    errorMsg.value.includes("invalid") ||
    errorMsg.value.includes("failed") ||
    errorMsg.value.includes("Incorrect")
      ? -1
      : 0;
  return valid >= 0;
};

export const encrypt = (msg) => {
  return msg ? btoa(msg) : null;
};

export const decrypt = (data) => {
  return data ? atob(data) : null;
};

export const userHasAny = (permissions) => {
  const store = useStore();
  let isAdmin = computed ( () => store.getters["auth/userIsAdmin"]);
  if (isAdmin.value) {
    return true;
  }
  let perm = computed ( () => store.getters["auth/permissions"]);
  if (perm.value === null || typeof perm.value === "undefined") {
    return false;
  }
  if (permissions === null || typeof permissions === "undefined") {
    return false;
  }
  for (let j = 0; j < permissions.length; j++) {
    if (perm.value.map((x) => x.key).includes(permissions[j])) {
      return true;
    }
  }
  return false;
};

export const userHasAll = (permissions) => {
  const store = useStore();
  let isAdmin = computed ( () => store.getters["auth/userIsAdmin"]);
  if (isAdmin.value) {
    return true;
  }
  let perm = computed ( () => store.getters["auth/permissions"]);
  if (perm.value === null || typeof perm.value === "undefined") {
    return false;
  }
  if (permissions === null || typeof permissions === "undefined") {
    return false;
  }
  for (let j = 0; j < permissions.length; j++) {
    if (!perm.value.map((x) => x.key).includes(permissions[j])) {
      return false;
    }
  }
  return true;
}
