import KpiService from "../../services/kpi.service";
import TicketsService from "@/services/tickets.service";

const kpiInitialState = {
  dashboardList: {},
  serverList: {},
  kpiResultsType: "total",
  dataIsLoading: false,

  dateBetween: {},
  dateBetweenReal: {},

  selectedKpi: "",
  selectedKpiDisplayName: "DETAILS",
  selectedMaintainer: null,
  selectedOwnerId: null,
  selectedShipImo: null,
  selectedShipId: null,

  importerKpiData: [],
  yearKPIPDFData: [],

  kpiData: [],
  watchdogKpiData: [],
  watchdogShipKpiData: [],
  watchdogKpiExcludions: [],
  watchdogShipServiceStatuses: [],

  kpiErrorMsg: "",
  kpiExclusionModificationStatus: {
    message: "",
    title: "",
    type: ""
  },
  toastData: {
    message: "",
    title: "",
    type: ""
  }
};

export const kpi = {
  namespaced: true,
  state: kpiInitialState,
  actions: {
    updateDate({ commit }, date) {
      commit("updateDate", date);
    },
    selectShip({ commit }, data) {
      commit("selectShip", data);
    },
    selectKpi({ commit }, kpi) {
      commit("selectKpi", kpi);
    },
    selectKpiDisplayName({ commit }, displayName) {
      commit("selectKpiDisplayName", displayName);
    },
    udpateTicketId({ commit }, tickets) {
      commit("tickets", tickets);
    },

    getDashboardList({ commit, state }, params) {
      return KpiService.getDashboardList()
        .then(
          (dashboardList) => {
            commit("dashboardList", dashboardList);
            return Promise.resolve(dashboardList);
          },
          (error) => {
            const errorMsg = error.response.data.message;
            commit("dashboardListFailure");
            commit("updateKpiErrorMsg", errorMsg);
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
          commit("dashboardListFailure");
        });
    },
    getDashboardKpi({ commit, state }, params) {
      return KpiService.getDashboardKpi(params)
        .then(
          (result) => {
            return Promise.resolve(result.data);
          },
          (error) => {
            const errorMsg = error.response.data.message;
            commit("updateKpiErrorMsg", errorMsg);
            return Promise.reject();
          }
        )
        .catch((error) => {
        });
    },
    getServerList({ commit, state }, maintainer) {
      return KpiService.getServerList(maintainer)
        .then(
          (serverList) => {
            commit("serverList", serverList);
            return Promise.resolve(serverList);
          },
          (error) => {
            const errorMsg = error.response.data.message;
            commit("serverListFailure");
            commit("updateKpiErrorMsg", errorMsg);
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
          commit("serverListFailure");
        });
    },
    getServerKpi({ commit, state }, params) {
      return KpiService.getServerKpi(params)
        .then(
          (result) => {
            return Promise.resolve(result.data);
          },
          (error) => {
            const errorMsg = error.response.data.message;
            commit("updateKpiErrorMsg", errorMsg);
            return Promise.reject();
          }
        )
        .catch((error) => {
        });
    },
    getImporterKpi({ commit }, params) {
      return KpiService.getImporterKpi(params)
        .then(
          (importerKpiParams) => {
            commit("getImporterKpiSuccess", importerKpiParams);
            return Promise.resolve(importerKpiParams);
          },
          (error) => {
            commit("getImporterKpiFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },

    getYearKPIPDF({ commit }, params) {
      return KpiService.getYearKPIPDF(params)
        .then(response => response.data)
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        })
        .then(blob => {
          console.log(blob);
          if (blob.type === "application/json") {
            commit("updateKpiErrorMsg", "No Report");
            return Promise.resolve(Blob);
          }
          const data = URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = data;
          link.target = "_blank";
          link.click();
          window.URL.revokeObjectURL(blob);
          return Promise.resolve(blob);
        });
    },
    getOwnersAndShipsKpi({ commit }, params) {
      return KpiService.getOwnersAndShipsKpi(params)
        .then(
          (KpiParams) => {
            commit("getOwnersAndShipsKpiSuccess", KpiParams);
            commit("setOwnersAndShipsKpiType");
            commit("saveDatesOptions", KpiParams);
            commit("setDates");
            return Promise.resolve(KpiParams);
          },
          (error) => {
            commit("getOwnersAndShipsKpiFailure");
            commit("saveDatesOptionsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message ?? "Error getting KPI data";
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    getWatchdogShipKpiData({ commit }, params) {
      return KpiService.getWatchdogShipKpi(params)
        .then(
          (watchdogKpiParams) => {
            commit("getWatchdogShipKpiDataSuccess", watchdogKpiParams);
            return Promise.resolve(watchdogKpiParams);
          },
          (error) => {
            commit("getWatchdogShipKpiDataFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    getWatchdogKpiExclusions({ commit }, kpiParams) {
      return KpiService.getWatchdogKpiExclusions(kpiParams)
        .then(
          (watchdogKpiParams) => {
            commit("getWatchdogKpiExclusionsSuccess", watchdogKpiParams);
            return Promise.resolve(watchdogKpiParams);
          },
          (error) => {
            commit("getWatchdogKpiExclusionsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    createWatchdogKpiExclusion({ commit }, kpiParams) {
      return KpiService.createWatchdogKpiExclusions(kpiParams)
        .then(
          (watchdogKpiParams) => {
            commit("updateExclusionStatus", {
              title: "Success!",
              msg: "Exclusion create success",
              type: "success"
            });
            return Promise.resolve(watchdogKpiParams);
          },
          (error) => {
            commit("updateExclusionStatus", {
              title: "Error!",
              msg: "Exclusion update failed",
              type: "error"
            });
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    updateWatchdogKpiExclusion({ commit }, kpiParams) {
      return KpiService.updateWatchdogKpiExclusions(kpiParams)
        .then(
          (watchdogKpiParams) => {
            if (kpiParams.deleted == 1) {
              commit("updateExclusionStatus", {
                title: "Success!",
                msg: "Exclusion delete success",
                type: "info"
              });
            } else {
              commit("updateExclusionStatus", {
                title: "Success!",
                msg: "Exclusion update success",
                type: "info"
              });
            }
            return Promise.resolve(watchdogKpiParams);
          },
          (error) => {
            commit("updateExclusionStatus", {
              title: "Error!",
              msg: "Exclusion update failed",
              type: "error"
            });
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    createServicesExclusions({ commit }, params) {
      return KpiService.createServicesExclusions(params)
        .then(
          (watchdogKpiParams) => {
            commit("updateExclusionStatus", {
              title: "Success!",
              msg: "Exclusion creation success",
              type: "success"
            });
            return Promise.resolve(watchdogKpiParams);
          },
          (error) => {
            commit("updateExclusionStatus", {
              title: "Error!",
              msg: "Exclusion creation fail",
              type: "error"
            });
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateKpiErrorMsg", errorMsg);
        });
    },
    clearKpiName({ commit }) {
      commit("clearKpiName");
    },
    setMaintainer({ commit }, maintainer) {
      commit("setMaintainer", maintainer);
    },
    clearErrorMsg({ commit }) {
      commit("updateKpiErrorMsg", "");
    },
    clearExclusionStatus({ commit }) {
      commit("updateExclusionStatus", null);
    },
    getTorquemeterFaultDetection({ commit }, params) {
      return KpiService.getTorquemeterFaultDetection(params)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success!",
              msg: result.data.message,
              type: "success"
            });
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateToastData", {
            title: "Error!",
            msg: errorMsg,
            type: "error"
          });
          return Promise.reject(error);
        });
    },
    getShipKpiDataHeatMap({ commit }, params) {
      return KpiService.getShipKpiDataHeatMap(params)
        .then(
          (result) => {
            commit("attachHeatmapData", { params: params, result: result });
            commit("updateToastData", {
              title: "Success!",
              msg: result.data.message,
              type: "success"
            });
            return Promise.resolve(result);
          }
        );
    }
  },
  mutations: {
    selectShip(state, data) {
      state.selectedShipId = data.shipId;
      state.selectedShipImo = data.shipImo;
      state.selectedOwnerId = data.ownerId;
    },
    selectKpi(state, kpi) {
      state.selectedKpi = kpi;
    },
    attachHeatmapData(state, input) {
      if (typeof (state.kpiData[input.params.kpi_name]) === "undefined") {
        return;
      }
      let owner = null;
      owner = state.kpiData[input.params.kpi_name].find(owner => {
        return owner.id === input.params.owner_id;
      });
      if (owner !== undefined && owner !== null) {
        const ship = owner.ships.find(ship => {
          return ship.ship_id === input.params.ship_id;
        });
        ship.chart_data_total = input.result.data.chart_data_total ?? [];
        ship.chart_data_sla = input.result.data.chart_data_sla ?? [];
        ship.chart_data_nosla = input.result.data.chart_data_nosla ?? [];
      }
    },

    setShipLoaded(state, params) {
      let owner = "";
      owner = state.kpiData[params.kpi].find((owner) => {
        return owner.id === params.owner_id;
      });
      const ship = owner.ships.find((ship) => {
        return ship.ship_id === params.ship_id;
      });
      ship.loaded = params.loaded;
    },

    dashboardList(state, dashboardList) {
      dashboardList.data.result.forEach((item) => {
        item.loaded = false;
        item.visible = false;
      });
      state.dashboardList = dashboardList.data.result;
      state.isDashboard = true;
    },
    dashboardListFailure(state) {
      state.dashboardList = [];
    },
    serverList(state, serverList) {
      serverList.data.result.forEach((item) => {
        item.loaded = false;
        item.visible = false;
      });
      state.serverList = serverList.data.result;
      state.selectedKpiDisplayName =
        serverList.data.kpi_name ?? "DETAILS";
    },
    serverListFailure(state) {
      state.serverList = [];
    },
    setKpiResultsType(state, newKpiResultsType) {
      state.kpiResultsType = newKpiResultsType;
      this.commit("kpi/setDates");
      this.commit("kpi/setOwnersAndShipsKpiType");
    },
    setDates(state) {
      if (state.kpiResultsType === "sla") {
        state.dateBetweenReal.startTime = state.dateBetweenReal.sla_startTime ?? undefined;
        state.dateBetweenReal.endTime = state.dateBetweenReal.sla_endTime ?? undefined;
      } else if (state.kpiResultsType === "nosla") {
        state.dateBetweenReal.startTime = state.dateBetweenReal.nosla_startTime ?? undefined;
        state.dateBetweenReal.endTime = state.dateBetweenReal.nosla_endTime ?? undefined;
      } else {
        state.dateBetweenReal.startTime = state.dateBetweenReal.total_startTime ?? undefined;
        state.dateBetweenReal.endTime = state.dateBetweenReal.total_endTime ?? undefined;
      }
    },
    saveDatesOptions(state, result) {
      state.dateBetweenReal.total_startTime =
        result.data.total_start_time ?? undefined;
      state.dateBetweenReal.total_endTime =
        result.data.total_end_time ?? undefined;
      state.dateBetweenReal.sla_startTime =
        result.data.sla_start_time ?? undefined;
      state.dateBetweenReal.sla_endTime =
        result.data.sla_end_time ?? undefined;
      state.dateBetweenReal.nosla_startTime =
        result.data.nosla_start_time ?? undefined;
      state.dateBetweenReal.nosla_endTime =
        result.data.nosla_end_time ?? undefined;
    },
    saveDatesOptionsFailure(state) {
      state.dateBetweenReal.total_startTime = undefined;
      state.dateBetweenReal.total_endTime = undefined;
      state.dateBetweenReal.sla_startTime = undefined;
      state.dateBetweenReal.sla_endTime = undefined;
      state.dateBetweenReal.nosla_startTime = undefined;
      state.dateBetweenReal.nosla_endTime = undefined;
    },
    setOwnersAndShipsKpiType(state) {
      for (let key in state.kpiData) {
        for (let i = 0; i < state.kpiData[key].length; i++) {
          if (state.kpiResultsType === "sla") {
            state.kpiData[key][i].kpi =
              typeof state.kpiData[key][i].sla != "string"
                ? state.kpiData[key][i].sla
                : undefined;
            state.kpiData[key][i].kpi_visibility =
              state.kpiData[key][i].sla_visibility ?? false;
            state.kpiData[key][i].dataArray =
              state.kpiData[key][i].chart_data_sla ?? [];
          } else if (state.kpiResultsType === "nosla") {
            state.kpiData[key][i].kpi =
              typeof state.kpiData[key][i].nosla != "string"
                ? state.kpiData[key][i].nosla
                : undefined;
            state.kpiData[key][i].kpi_visibility =
              state.kpiData[key][i].nosla_visibility ?? false;
            state.kpiData[key][i].dataArray =
              state.kpiData[key][i].chart_data_nosla ?? [];
          } else {
            state.kpiData[key][i].kpi =
              typeof state.kpiData[key][i].total != "string"
                ? state.kpiData[key][i].total
                : undefined;
            state.kpiData[key][i].kpi_visibility =
              state.kpiData[key][i].total_visibility ?? false;
            state.kpiData[key][i].dataArray =
              state.kpiData[key][i].chart_data_total ?? [];
          }
          for (let j = 0; j < state.kpiData[key][i].ships.length; j++) {
            if (state.kpiResultsType === "sla") {
              state.kpiData[key][i].ships[j].kpi =
                typeof state.kpiData[key][i].ships[j].sla != "string"
                  ? state.kpiData[key][i].ships[j].sla
                  : undefined;
              state.kpiData[key][i].ships[j].kpi_visibility =
                state.kpiData[key][i].ships[j].sla_visibility ?? false;
              state.kpiData[key][i].ships[j].dataArray =
                state.kpiData[key][i].ships[j].chart_data_sla ?? [];
            } else if (state.kpiResultsType === "nosla") {
              state.kpiData[key][i].ships[j].kpi =
                typeof state.kpiData[key][i].ships[j].nosla != "string"
                  ? state.kpiData[key][i].ships[j].nosla
                  : undefined;
              state.kpiData[key][i].ships[j].kpi_visibility =
                state.kpiData[key][i].ships[j].nosla_visibility ?? false;
              state.kpiData[key][i].ships[j].dataArray =
                state.kpiData[key][i].ships[j].chart_data_nosla ?? [];
            } else {
              state.kpiData[key][i].ships[j].kpi =
                typeof state.kpiData[key][i].ships[j].total != "string"
                  ? state.kpiData[key][i].ships[j].total
                  : undefined;
              state.kpiData[key][i].ships[j].kpi_visibility =
                state.kpiData[key][i].ships[j].total_visibility ?? false;
              state.kpiData[key][i].ships[j].dataArray =
                state.kpiData[key][i].ships[j].chart_data_total ?? [];
            }
          }
        }
      }
    },
    getYearKPIPDFSuccess(state, yearKPIPDFParams) {
      state.yearKPIPDFData = yearKPIPDFParams.data.kpis;
    },
    getYearKPIPDFFailure(state) {
      state.yearKPIPDFData = [];
    },

    getImporterKpiSuccess(state, importerKpiParams) {
      state.importerKpiData = importerKpiParams.data.kpis;
      state.selectedKpiDisplayName =
        importerKpiParams.data.kpi_name ?? "DETAILS";
    },
    getImporterKpiFailure(state) {
      state.importerKpiData = [];
      if (
        state.selectedKpiDisplayName === undefined ||
        state.selectedKpiDisplayName === null ||
        state.selectedKpiDisplayName === ""
      ) {
        state.selectedKpiDisplayName = "DETAILS";
      }
    },
    getOwnersAndShipsKpiSuccess(state, result) {
      state.kpiData[state.selectedKpi] = result.data.kpis;
      state.selectedKpiDisplayName = result.data.kpi_name;
    },
    getOwnersAndShipsKpiFailure(state) {
      state.kpiData[state.selectedKpi] = [];
      if (
        state.selectedKpiDisplayName === undefined ||
        state.selectedKpiDisplayName === null ||
        state.selectedKpiDisplayName === ""
      ) {
        state.selectedKpiDisplayName = "DETAILS";
      }
    },

    getWatchdogShipKpiDataSuccess(state, watchdogKpiShipParams) {
      state.watchdogShipKpiData = watchdogKpiShipParams.data.kpis;
      state.selectedKpiDisplayName =
        watchdogKpiShipParams.data.kpi_name ?? "DETAILS";
      state.watchdogShipServiceStatuses = watchdogKpiShipParams.data.statuses;
    },

    getWatchdogShipKpiDataFailure(state) {
      state.watchdogShipKpiData = [];
      state.watchdogShipServiceStatuses = [];
      if (
        state.selectedKpiDisplayName === undefined ||
        state.selectedKpiDisplayName === null ||
        state.selectedKpiDisplayName === ""
      ) {
        state.selectedKpiDisplayName = "DETAILS";
      }
    },

    getWatchdogKpiExclusionsSuccess(state, watchdogKpiExclusions) {
      state.watchdogKpiExcludions = watchdogKpiExclusions.data.exclusions;
    },

    getWatchdogKpiExclusionsFailure(state) {
      state.watchdogKpiExcludions = [];
    },

    setMaintainer(state, maintainer) {
      state.selectedMaintainer = maintainer;
    },

    clearKpiName(state) {
      state.selectedKpi = "";
      state.selectedKpiDispalyName = "DETAILS";
    },
    selectKpiDisplayName(state, displayName) {
      state.selectedKpiDisplayName = displayName;
    },

    updateDate(state, date) {
      state.dateBetween.dateFrom = date.dateFrom;
      state.dateBetween.dateTo = date.dateTo;
    },

    updateKpiErrorMsg(state, errorMsg) {
      state.kpiErrorMsg = errorMsg;
    },

    updateExclusionStatus(state, params) {
      if (params) {
        let stat = {
          title: params.title,
          message: params.msg,
          type: params.type
        };
        state.kpiExclusionModificationStatus = stat;
      } else {
        state.kpiExclusionModificationStatus = {};
      }
    },
    updateToastData(state, data) {
      if (data) {
        let stat = {
          title: data.title,
          message: data.msg,
          type: data.type
        };
        state.toastData = stat;
      } else {
        state.toastData = {};
      }
    },
    setDataIsLoading(state, value) {
      state.dataIsLoading = value;
    }
  },
  getters: {
    kpiData: (state) => {
      return state.kpiData;
    },
    kpiAlarms: (state) => {
      return state.evtKpiAlarms;
    },
    dateBetween: (state) => {
      return state.dateBetween;
    },
    dateBetweenReal: (state) => {
      return state.dateBetweenReal;
    },
    selectedKpi: (state) => {
      return state.selectedKpi;
    },
    selectedKpiDisplayName: (state) => {
      return state.selectedKpiDisplayName;
    },
    selectedMaintainer: (state) => {
      return state.selectedMaintainer;
    },
    importerKpiData: (state) => {
      return state.importerKpiData;
    },
    yearKPIPDFData: (state) => {
      return state.yearKPIPDFData;
    },
    watchdogShipKpi: (state) => {
      return state.watchdogShipKpiData;
    },
    watchdogShipServiceStatuses: (state) => {
      return state.watchdogShipServiceStatuses;
    },
    watchdogKpiExcludions: (state) => {
      return state.watchdogKpiExcludions;
    },

    errorMessage: (state) => {
      return state.kpiErrorMsg;
    },
    exclusionStatus: (state) => {
      return state.kpiExclusionModificationStatus;
    },
    toastData: (state) => {
      return state.toastData;
    },
    dashboardList: (state) => {
      return state.dashboardList;
    },
    kpiResultsType: (state) => {
      return state.kpiResultsType;
    },
    dataIsLoading: (state) => {
      return state.dataIsLoading;
    },
    serverKpiList: (state) => {
      return state.serverList;
    },
    getKpiData: (state) => {
      return state.kpiData;
    }
  }
};
