import axios from "axios";
import authHeader from "./auth-header";

class DeliveryService {
  getList() {
    return axios.get(
      `${
        process.env.VUE_APP_API
      }/spares_delivery`,
      {
        headers: authHeader()
      }
    );
  }

  updateRow(row) {
    return axios.post(
      `${
        process.env.VUE_APP_API
      }/spares_delivery_update_row`, row,
      {
        headers: authHeader()
      }
    )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  newRow(row) {
    return axios.post(
      `${
        process.env.VUE_APP_API
      }/spares_delivery_new_row`, row,
      {
        headers: authHeader()
      }
    )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getTicketsForShip(params) {
    let str_header = "?";
    let params_str = "";
    if (params) {
      params_str = params["ship_id"] ?
        str_header.concat("ship_id=", params["ship_id"]) :
        "";
      params_str = params["maintainer"] ?
        params_str.concat("&maintainer=", params["maintainer"]) : null;
    }
    return axios.get(
      `${
        process.env.VUE_APP_API
      }/get_tickets_history${params_str}`,
      {
        headers: authHeader()
      }
    );
  }
}

export default new DeliveryService();
