import ShipsService from "../../services/ships.service";
import store from "../index";
import DeliveryService from "../../services/delivery.service";

const deliveryInitialState = {
  list: [],
  maintainers: [],
  owners: [],
  ships: [],
  tickets: [],

  toastData: {
    message: "",
    title: "",
    type: ""
  }
};

export const delivery = {
  namespaced: true,
  state: deliveryInitialState,
  actions: {
    getList({ commit }) {
      return DeliveryService.getList()
        .then(
          (result) => {
            commit("getList", result.data);
            return Promise.resolve(result);
          }
        )
        .catch((error) => {
          let message = error;
          if (
            "response" in error &&
            "data" in error.response &&
            "message" in error.response.data &&
            error.response.data.message !== "") {
            message = error.response.data.message;
          }
          commit("updateToastData", {
            title: "Error!",
            msg: message,
            type: "error"
          });
          return Promise.reject(error);
        });
    },
    // this is the V2 version (supporting native and virtual maintainers)
    getMaintainers({ commit }, params) {
      return ShipsService.getMaintainersV2(params)
        .then(
          (result) => {
            commit("getMaintainersSuccess", result.data.maintainers);
            return Promise.resolve(result);
          }
        )
        .catch((error) => {
          let message = error;
          if (
            "response" in error &&
            "data" in error.response &&
            "message" in error.response.data &&
            error.response.data.message !== "") {
            message = error.response.data.message;
          }
          commit("updateToastData", {
            title: "Error!",
            msg: message,
            type: "error"
          });
          return Promise.reject(error);
        });
    },
    getOwners({ commit }, maintainer) {
      return ShipsService.getOwnersV2(maintainer)
        .then(
          (result) => {
            commit("getOwnersSuccess", [maintainer, result.data.owners]);
            return Promise.resolve(result);
          })
        .catch((error) => {
          let message = error;
          if (
            "response" in error &&
            "data" in error.response &&
            "message" in error.response.data &&
            error.response.data.message !== "") {
            message = error.response.data.message;
          }
          commit("updateToastData", {
            title: "Error!",
            msg: message,
            type: "error"
          });
          return Promise.reject(error);
        });
    },
    getShips({ commit }, params) {
      return ShipsService.getOwnerShipsV2(params.maintainer, params.ownerId)
        .then(
          (result) => {
            commit("getShipsSuccess", [params, result.data.ship]);
            return Promise.resolve(result);
          })
        .catch((error) => {
          let message = error;
          if (
            "response" in error &&
            "data" in error.response &&
            "message" in error.response.data &&
            error.response.data.message !== "") {
            message = error.response.data.message;
          }
          commit("updateToastData", {
            title: "Error!",
            msg: message,
            type: "error"
          });
          return Promise.reject(error);
        });
    },
    updateRow({ commit }, params) {
      return DeliveryService.updateRow(params)
        .then(
          (result) => {
            commit("updateToastData", {
              title: "Success",
              msg: result.data.message,
              type: "success"
            });
            //commit("updateRow", result.data.row)
            return Promise.resolve(result);
          })
        .catch((error) => {
          let message = error;
          if ("response" in error &&
            "data" in error.response &&
            "message" in error.response.data &&
            error.response.data.message !== "") {
            message = error.response.data.message;
          }
          commit("updateToastData", {
            title: "Error!",
            msg: message,
            type: "error"
          });
          commit("showToastValidationErrors", error.response.data.errors);
          return Promise.reject(error);
        });
    },
    newRow({ commit }, params) {
      return DeliveryService.newRow(params)
        .then((result) => {
          return Promise.resolve(result);
        })
        .catch((error) => {
            let message = error;
            if ("response" in error &&
              "data" in error.response &&
              "message" in error.response.data &&
              error.response.data.message !== "") {
              message = error.response.data.message;
            }
            commit("updateToastData", {
              title: "Error!",
              msg: message,
              type: "error"
            });
            commit("showToastValidationErrors", error.response.data.errors);
            return Promise.reject(error);
          }
        );
    },
    getTicketsForShip({ commit }, params) {
      return DeliveryService.getTicketsForShip(params)
        .then((result) => {
          commit("getTicketsSuccess", result.data.tickets);
          return Promise.resolve();
        })
        .catch((error) => {
          commit("getTicketsFailure");
          let message = error;
          if ("response" in error &&
            "data" in error.response &&
            "message" in error.response.data &&
            error.response.data.message !== "") {
            message = error.response.data.message;
          }
          commit("updateToastData", {
            title: "Error!",
            msg: message,
            type: "error"
          });
          commit("showToastValidationErrors", error.response.data.errors);
          return Promise.reject(error);
        });
    }
  },
  mutations: {
    updateToastData(state, data) {
      if (data) {
        let stat = {
          title: data.title,
          message: data.msg,
          type: data.type
        };
        state.toastData = stat;
      } else {
        state.toastData = {};
      }
    },
    async showToastValidationErrors(state, errors) {
      await new Promise(resolve => setTimeout(resolve, 500));
      let time = 500;
      if (errors !== undefined &&
        errors !== null) {
        for (const property in errors) {
          for (const value of errors[property]) {
            let stat = {
              title: property,
              message: value,
              type: "error"
            };
            state.toastData = stat;
            await new Promise(resolve => setTimeout(resolve, 500));
          }
        }
      } else {
        state.toastData = {};
      }

    },
    getList(state, data) {
      state.list = data.data;
    },
    getMaintainersSuccess(state, maintainers) {
      state.maintainers = maintainers;
    },
    getOwnersSuccess(state, array_of_data) {
      let [maintainer, owners] = array_of_data;
      state.owners[maintainer] = owners.map((x) => {
        return {
          owner_id: x.id,
          owner_name: x.owner_name
        };
      });
    },
    getMaintainersFailure(state) {
      state.maintainers = [];
    },
    getShipsSuccess(state, array_of_data) {
      let [{ maintainer, ownerId }, ships] = array_of_data;
      state.ships[maintainer] = [];
      state.ships[maintainer][ownerId] = ships;
    },
    updateRow(state, row) {
      state.list.map((x) => {
        x.id !== row.id ? x : row;
      });
    },
    getTicketsSuccess(state, tickets) {
      state.tickets = tickets;
    },
    getTicketsFailure(state) {
      state.tickets = [];
    }
  },
  getters: {
    toastData: (state) => {
      return state.toastData;
    },
    list: (state) => {
      return state.list;
    },
    maintainers: (state) => {
      return state.maintainers;
    },
    owners: (state) => {
      return state.owners ?? [];
    },
    ships: (state) => {
      return state.ships ?? [];
    },
    tickets: (state) => {
      return state.tickets ?? [];
    }
  }
};
