import ShipsService from "../../services/ships.service";
import store from "../index";

const exFormData = localStorage.getItem("exclusionEditData");
const localStorageExForm = exFormData ? JSON.parse(JSON.parse(exFormData)) : {};

const shipsInitialState = {
  maintainers: [],
  owners: [],
  ships: [],

  selectedMaintainer: "",
  selectedMaintainerNative: "",
  selectedMaintainerDisplayName: null,
  selectedOwner: "",
  selectedShip: {},
  exForm: localStorageExForm,

  shipsLoading: false,

  dateBetween: {},
  kpiData: {},
  kpiAlarms: {},

  errorMsg: ""
};

export const ships = {
  namespaced: true,
  state: shipsInitialState,
  actions: {
    selectMaintainer({ commit }, maintainer) {
      commit("selectMaintainer", maintainer);
      commit("selectMaintainerDisplayName", maintainer);
    },
    selectMaintainerNative({ commit }, maintainer) {
      commit("selectMaintainerNative", maintainer);
      //commit("selectMaintainerDisplayName", maintainer);
    },
    selectMaintainerDisplayName({ commit }, maintainer) {
      commit("selectMaintainerDisplayName", maintainer);
    },
    setExclusionData({ commit }, exclData) {
      commit("setExclusionData", exclData);
    },
    selectOwner({ commit }, owner) {
      commit("selectOwner", owner);
    },
    selectShip({ commit, store }, ship) {
      commit("selectShip", ship);
    },

    setShipsLoading({ commit }, loading) {
      commit("setShipsLoading", loading);
    },
    updateDate({ commit }, date) {
      commit("updateDate", date);
    },

    getMaintainersV2({ commit }, params) {
      return ShipsService.getMaintainersV2(params)
        .then(
          (result) => {
            commit("getMaintainersV2Success", result.data.maintainers);
            return Promise.resolve(result);
          },
          (error) => {
            commit("getMaintainersV2Failure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateErrorMsg", errorMsg);
        });
    },
    getOwners({ commit, state }) {
      return ShipsService.getOwners(state.selectedMaintainer)
        .then(
          (result) => {
            commit("getOwnersSuccess", result.data);
            return Promise.resolve(result);
          },
          (error) => {
            commit("getOwnersFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateErrorMsg", errorMsg);
        });
    },
    getOwnersV2({ commit, state }) {
      return ShipsService.getOwnersV2(state.selectedMaintainer)
        .then(
          (result) => {
            commit("getOwnersV2Success", result.data.owners);
            return Promise.resolve(result);
          },
          (error) => {
            commit("getOwnersV2Failure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateErrorMsg", errorMsg);
        });
    },
    addSla({ commit }, data) {
      return ShipsService.addSla(data)
        .then(
          (result) => {
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateErrorMsg", errorMsg);
        });
    },
    getOwnerShips({ commit, state }) {
      commit("setShipsLoading", true);
      return ShipsService.getOwnerShips(
        state.selectedMaintainer,
        state.selectedOwner
      )
        .then(
          (result) => {
            commit("getOwnerShipsSuccess", result.data.ship);
            return Promise.resolve(result);
          },
          (error) => {
            commit("getOwnerShipsFailure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateErrorMsg", errorMsg);
        });
    },
    getOwnerShipsV2({ commit, state }) {
      commit("setShipsLoading", true);
      return ShipsService.getOwnerShipsV2(
        state.selectedMaintainer,
        state.selectedOwner
      )
        .then(
          (result) => {
            commit("getOwnerShipsV2Success", result.data.ship);
            return Promise.resolve(result);
          },
          (error) => {
            commit("getOwnerShipsV2Failure");
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateErrorMsg", errorMsg);
        });
    },
    getShip({ commit }, params) {
      return ShipsService.getShipById(params)
        .then(
          (result) => {
            result.data.ship[0].maintainer = params.maintainer;
            if (!result.data.ship[0].sla) result.data.ship[0].sla = 0;
            if (!result.data.ship[0].sla_from) result.data.ship[0].sla_from = 0;
            if (!result.data.ship[0].sla_to) result.data.ship[0].sla_to = 0;
            commit("selectShip", result.data.ship[0]);
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateErrorMsg", errorMsg);
        });
    },
    getShipV2({ commit }, params) {
      return ShipsService.getShipByIdV2(params)
        .then(
          (result) => {
            result.data.ship[0].maintainer = params.maintainer;
            result.data.ship[0].maintainerDisplayName =
              params.maintainerDisplayName;
            if (!result.data.ship[0].sla) result.data.ship[0].sla = 0;
            if (!result.data.ship[0].sla_from) result.data.ship[0].sla_from = 0;
            if (!result.data.ship[0].sla_to) result.data.ship[0].sla_to = 0;
            commit("selectShip", result.data.ship[0]);
            return Promise.resolve(result);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateErrorMsg", errorMsg);
        });
    },
    getShipKpi({ commit }, params) {
      return ShipsService.getShipKpi(params)
        .then(
          (result) => {
            commit("getShipKpiSuccess", result);
            return Promise.resolve(result);
          },
          (error) => {
            commit("getShipKpiFailure", error);
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          const errorMsg = error.response.data.message;
          commit("updateErrorMsg", errorMsg);
        });
    },

    clearErrorMsg({ commit }) {
      commit("updateErrorMsg", "");
    },
    resetOwnerId({ commit }) {
      commit("resetOwnerId");
    }
  },
  mutations: {
    selectMaintainer(state, maintainer) {
      state.selectedMaintainer = maintainer;
    },
    selectMaintainerNative(state, maintainer) {
      state.selectedMaintainerNative = maintainer;
    },
    selectMaintainerDisplayName(state, maintainer) {
      if (state.maintainers.length < 1) {
        store.dispatch("ships/getMaintainersV2").then(() => {
        });
      }
      for (let i = 0; i < state.maintainers.length; i++) {
        if (state.maintainers[i]["name"] === maintainer) {
          state.selectedMaintainerDisplayName =
            state.maintainers[i]["display_name"];
        }
      }
    },
    setExclusionData(state, data) {
      state.exForm = data;
    },
    selectOwner(state, owner) {
      state.selectedOwner = owner;
    },
    selectShip(state, ship) {
      state.selectedShip = ship;
    },

    setShipsLoading(state, loading) {
      state.shipsLoading = loading;
    },
    updateDate(state, date) {
      state.dateBetween.dateFrom = date.dateFrom;
      state.dateBetween.dateTo = date.dateTo;
    },

    getMaintainersV2Success: function(state, maintainers) {
      state.maintainers = maintainers;
      if (
        state.selectedMaintainer === undefined ||
        state.selectedMaintainer === null ||
        state.selectedMaintainer === ""
      ) {
        state.selectedMaintainer = maintainers[0].name ?? "";
        state.selectedMaintainerDisplayName =
          maintainers[0].display_name ?? state.selectedMaintainer;
      }
    },
    getMaintainersV2Failure(state) {
      state.maintainers = null;
    },
    getOwnersSuccess(state, owners) {
      state.owners = owners;
    },
    getOwnersFailure(state) {
      state.owners = null;
    },
    getOwnersV2Success(state, owners) {
      state.owners = owners;
    },
    getOwnersV2Failure(state) {
      state.owners = null;
    },

    getOwnerShipsSuccess(state, ships) {
      state.ships = ships;
    },
    getOwnerShipsFailure(state) {
      state.ships = null;
    },
    getOwnerShipsV2Success(state, ships) {
      state.ships = ships;
    },
    getOwnerShipsV2Failure(state) {
      state.ships = null;
    },

    getShipKpiSuccess(state, getKpiResult) {
      state.kpiData = getKpiResult.data.kpi;
      state.kpiAlarms = getKpiResult.data.alarms;
    },
    getShipKpiFailure(state) {
      state.kpiData = null;
    },

    updateErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    resetOwnerId(state) {
      state.selectedOwner = null;
    }
  },
  getters: {
    maintainers: (state) => {
      return state.maintainers;
    },
    owners: (state) => {
      return state.owners;
    },
    ships: (state) => {
      return state.ships;
    },

    selectedMaintainer: (state) => {
      return state.selectedMaintainer;
    },
    selectedMaintainerNative: (state) => {
      return state.selectedMaintainerNative;
    },
    selectedMaintainerDisplayName: (state) => {
      return state.selectedMaintainerDisplayName ?? state.selectedMaintainer;
    },
    getExclusionData: (state) => {
      return state.exForm;
    },
    selectedOwner: (state) => {
      return state.selectedOwner;
    },
    selectedOwnerName: (state) => {
      return state.owners.find((o) => o.id == state.selectedOwner)[
        "owner_name"
        ];
    },
    selectedShip: (state) => {
      return state.selectedShip;
    },

    shipsLoading: (state) => {
      return state.shipsLoading;
    },

    dateBetween: (state) => {
      return state.dateBetween;
    },
    kpiData: (state) => {
      return state.kpiData;
    },
    kpiAlarms: (state) => {
      return state.kpiAlarms;
    },

    errorMessage: (state) => {
      return state.errorMsg;
    }
  }
};
